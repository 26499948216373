'use client';

import React, { useEffect, useState } from 'react';

import LoginPageClientComponent from '@/components/authentication/LoginPageClientComponent';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export default function Login() {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  if (window.matchMedia('(display-mode: standalone)').matches) {
    window.location.href = 'evernote://';
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    // Register service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').catch(console.error);
    }

    // Listen for install prompt
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();

    const result = await deferredPrompt.userChoice;
    console.log('User choice:', result.outcome);

    setDeferredPrompt(null); // Clear it
  };

  return (
    <div className="w-full max-w-[550px] px-6 py-2 text-center md:px-12 md:pb-16">
      <LoginPageClientComponent />
      {deferredPrompt && (
        <button
          onClick={handleInstallClick}
          className="mt-4 rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700"
        >
          Install Evernote App
        </button>
      )}
    </div>
  );
}
